import { fetchWrapper} from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL+"/creator-games";

export const creatorGamesService = {
    create,
    getAll, 
    deleteSelected,
    update,
}


/**
 * Send post request to create new creator-game in database
 * @param {obiect} params 
 * @returns server response
 */

function create(params) {
    return fetchWrapper.post(baseUrl, params);
}


/**
 * Send get request to get all creator-games from database
 * @returns server response
 */

function getAll() {
    return fetchWrapper.get(`${baseUrl}/getAll`);
}


/**
 * Send put request to update creator-game in database
 * @param {string} id 
 * @param {obiect} params 
 * @returns server response
 */

function update(id,params){
    return fetchWrapper.put(`${baseUrl}/${id}`, params)
}

function deleteSelected(ids) {
    return fetchWrapper.delete(baseUrl, ids);
}
