import { fetchWrapper} from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL+"/categories";

export const categoryService = {
    create,
    getCategories
};


/**
 * Send post to save new category in database
 */
function create(params) {
    return fetchWrapper.post(baseUrl, params);
}

/**
 *  Get all categories from database
 */
function getCategories() {
    return fetchWrapper.get(baseUrl);
}
