import React, { useRef, useState } from 'react';
import {
  Container, Row, Col, Card,
} from 'react-bootstrap';
import { roundService, alertService } from '~root/_services';
import RoundForm from './components/RoundForm';
import RoundList from './components/RoundList';

const Round = ({ setRerenderGames, rerenderGames }) => {
  const [selectedRound, setRound] = useState(undefined)
  const [defaultTags, setDefaultTags] = useState([]);
  const listRef = useRef();
  const formRef = useRef();

  const handleRoundSelect = ({ id, name, points, time, hashtags, question_count }) => {
    if (selectedRound?.id === id) return;

    const formattedHashtags = hashtags;

    setRound({ id, name, points, time, hashtags: formattedHashtags, question_count })
  }

  const handleRoundDelete = (ids) => {
    console.log("Do usunięcia: ", ids);
    roundService.deleteSelected(ids)
      .then(() => {
        listRef.current.reloadRounds();
        setRound(undefined);
        formRef.current.reset();
      })
      .catch((err) => {
        alertService.error(`Błąd usuwania rund. ${err}`);
      });
  }

  const handleRoundSave = async (id, props) => {
    if (id) await roundService.update(id, props);
    else await roundService.create(props);

    setRound(undefined);
    setRerenderGames(!rerenderGames)

    listRef.current.reloadRounds();

    return true;
  }

  return (
    <Container fluid>
      <Row className="h-100">
        <Col>
          <Card>
            <Card.Body>
              <Row className="h-100">
                <Col lg={9}>
                  <RoundList
                    ref={listRef}
                    selectRound={handleRoundSelect}
                    deleteSelected={handleRoundDelete}
                    setDefaultTags={setDefaultTags}
                  />
                </Col>
                <Col lg={3}>
                  <RoundForm
                    formRef={formRef}
                    selected={selectedRound}
                    saveRound={handleRoundSave}
                    clean={() => { setRound(undefined); }}
                    defaultTags={defaultTags}
                    setDefaultTags={setDefaultTags}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
};

export default Round;
