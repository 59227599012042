import React, {useState, useEffect} from "react"
import Table from "./components/Table"
import {Container, Row, Col, Card} from "react-bootstrap"
import { alertService, gameService, hostedGameService } from "~root/_services"

export default function Disabled({ games, getGames, refreshAll }) {

    const [selected, setSelected] = useState(undefined)

    const gameSelected = (props) => {
        setSelected(props)
    }

    const handleGameDelete = (ids) => {
        gameService.deleteSelected(ids)
        .then(res => {
            getGames();
        })
        .catch(err => {
            alertService.error("Wystąpił błąd podczas usuwania gier: "+err);
        })
    }

    return (
        <Container fluid>
            <Row className="h-100">
                <Col>
                    <Card>
                        <Card.Body>
                            <Row className="h-100">
                                <Col lg={9} style={{minHeight: 600}}>
                                    <Table
                                        data={games}
                                        handleRowClick={gameSelected}
                                        deleteSelected={handleGameDelete}
                                        reloadData={refreshAll}
                                    />
                                </Col>
                                <Col lg={3}>
                                    
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}