import { fetchWrapper } from "~root/_helpers";

const baseUrl = process.env.REACT_APP_SERVER_URL + "/questions";

export const questionService = {
  create,
  update,
  deleteSelected,
  deleteQuestion,
  getAllFromRound,
  uploadQuestions,
  getAll
};

/**
 * Send post request to create new Question in database
 * @param {object} params 
 * @returns respond
 */
function create(params) {
  return fetchWrapper.post(baseUrl, params);
}


/**
 * Send put request to update Question from database
 * @param {string} id 
 * @param {object} params 
 * @returns respond
 */
function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}


/**
 * Send delete request to remove selected Questions from database
 * @param {array} ids 
 * @returns respond
 */
function deleteSelected(ids){
  return fetchWrapper.delete(baseUrl, ids)
}


/**
 * Send delete request to remove selected Question from database
 * @param {string} id 
 * @returns respond
 */
function deleteQuestion(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}


/**
 * Send get request to get all Questions from round from database
 * @param {string} id 
 * @returns respond
 */
function getAllFromRound(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}


/**
 * Send get request to ger all Questions from database
 * @returns respond
 */
function getAll(){
  console.log("GET ALL QUESTIONS from", baseUrl)
  return fetchWrapper.get(baseUrl);
}


/**
 * Uploads Questions from a file and send them to database
 * @param {file} file 
 * @param {boolean} skipFirstRow 
 * @returns 
 */
function uploadQuestions({ file, skipFirstRow }) {
  return fetchWrapper.postFile(
    `${baseUrl}/readQuestions`,
    { file },
    { skipFirstRow }
  );
}
