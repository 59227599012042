import React, { Component } from 'react'
import {
    Table
   } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import EditIcon from '~root/shared/icons/EditIcon'
import DeleteIcon from '~root/shared/icons/DeleteIcon'

class Rounds extends Component {
    render() {
        return (
            <Table className="table--bordered" hover responsive>
              <thead key={"row_head"}>
                <tr>
                  <th key={"head1"}>Tytuł</th>
                  <th key={"head2"}>Kategoria</th>
                  <th key={"head3"}>Ilość pytań</th>
                  <th key={"head4"}></th>
                </tr>
              </thead>
              <tbody key={"row_body"}>
                {
                    this.props.rounds.map((item)=>{
                        return(
                            <tr style={{"cursor":"pointer"}} key={"round_"+item._id}>
                                <td>{item.title}</td>
                                <td>{item.question_category.content}</td>
                                <td>{item.question_count}</td>
                                <td className="d-flex flex-row align-items-center justify-content-end">
                                <EditIcon animated={true} clickHandle={()=>{this.props.editRound(item)}}/>  
                                <DeleteIcon animated={true} clickHandle={()=>this.props.deleteRound(item._id)}/>
                                </td>
                            </tr>
                        )
                    })
                }
              </tbody>
            </Table>

        )
    }
}

export default (withTranslation('common'))(Rounds)
