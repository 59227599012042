import { fetchWrapper} from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL+"/rounds";

export const roundService = {
    create,
    update,
    getRounds,
    getAll,
    remove,
    deleteSelected,
};


/**
 * Send post request to create new Round in database
 * @param {obiect} params 
 * @returns server response
 */

function create(params) {
    console.log(params)
    return fetchWrapper.post(baseUrl, params);
}


/**
 * Send put request to update Round in database
 * @param {string} id 
 * @param {obiect} params 
 * @returns server response
 */

function update(id,params) {
    return fetchWrapper.put(`${baseUrl}/${id}`, params);
}


/**
 * Send get request to get Round from database
 * @param {string} id 
 * @returns server response
 */

function getRounds(id) {
    return fetchWrapper.get(`${baseUrl}/getRounds/${id}`);
}


/**
 * Send get request to get all Rounds from database
 * @returns server response
 */

function getAll() {
    return fetchWrapper.get(baseUrl+"/getAll");
}


/**
 * Send delete request to remove Round from database
 * @param {string} id 
 * @returns server response
 */

function remove(id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`);
}


/**
 * Send delete request to delete selected Rounds from database
 * @param {array} ids 
 * @returns server response
 */
function deleteSelected(ids){
    return fetchWrapper.delete(baseUrl, ids)
  }