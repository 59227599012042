import React, {
    useEffect, useState, useImperativeHandle, forwardRef,
  } from 'react';
  import { DataGrid } from '@mui/x-data-grid';
  import { Button, Badge } from 'react-bootstrap';
  import { alertService, creatorGamesService } from '~root/_services';
  
  const columns = [
    // { field: 'id', headerName: 'ID', width: 250 },
    {
      field: 'name',
      headerName: 'Nazwa',
      editable: false,
      width: 250,
    },
    {
      field: 'rounds',
      headerName: 'Rundy',
      editable: false,
      width: 300,
      renderCell: (cell) => cell.value && cell.value.map((item, index) => (
        <h3 className="mr-1" key={`hashtag_${index}`}>
          <Badge variant="primary">
            {item?.name}
          </Badge>
        </h3>
      )),
    },
    { field: 'category', headerName: 'Kategoria', width: 200,
      renderCell: (cell) => (
        <h3>
          <Badge variant="warning">
            {cell.value}
          </Badge>
        </h3>
      )
     },
  ];
  
  const GameList = forwardRef(({ selectGame, deleteSelected }, ref) => {
    const [games, setGames] = useState([]);
    const [selectedIds, setSelection] = useState([]);
  
    useImperativeHandle(ref, () => ({
      reloadGames() {
        getGames();
      },
    }));
  
    const getGames = () => {
      creatorGamesService.getAll()
        .then((res) => {
          setGames(res);
        })
        .catch((err) => {
          alertService.error(`Nie udało się pobrać pytań. ${err}`);
        });
    };
  
    const handleRowClick = (props) => {
      selectGame(props.row);
    };
  
    useEffect(() => {
      getGames();
    }, []);
  
    return (
      <div style={{ height: '100%', width: '100%', minHeight: 300 }}>
        <DataGrid
          rows={games}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          onRowClick={handleRowClick}
          onSelectionModelChange={(props) => {
            setSelection(props.selectionModel);
          }}
          components={{
            Footer: () => {
              if (selectedIds.length > 0) {
                return (
                  <Button
                    variant="danger"
                    type="button"
                    className="ml-3"
                    onClick={() => {
                      deleteSelected(selectedIds);
                      setSelection([]);
                    }}
                  >
                    USUŃ ZAZNACZONE
                  </Button>
                );
              }
              return null;
            },
          }}
        />
      </div>
    );
  });
  
  export default GameList;
  