import React, { useRef, useState } from 'react';
import {
  Container, Row, Col, Card,
} from 'react-bootstrap';
import { alertService, questionService } from '~root/_services';
import QuestionForm from './components/QuestionForm';
import QuestionList from './components/QuestionList';
import Stats from './components/QuestionStats/QuestionStats';

export default function QuestionsDatabase() {
  const [selectedQuestion, setQuestion] = useState(undefined);
  const [defaultTags, setDefaultTags] = useState([]);
  const listRef = useRef();
  const formRef = useRef();

  const handleQuestionSelect = ({
    answers, content, id, correct_answer, hashtags,
  }) => {
    if (selectedQuestion?.id === id) return;

    // Tags.loadTags(hashtags)

    // console.log(hashtags)
    const formattedHashtags = hashtags;
    // const formattedHashtags = hashtags.join(' ');
    setQuestion({
      answers, content, id, correct_answer, hashtags: formattedHashtags,
    });
  };

  const handleQuestionSave = async (id, props) => {
    if (id) await questionService.update(id, props);
    else await questionService.create(props);

    setQuestion(undefined);

    listRef.current.reloadQuestions();

    return true;
  };

  const handleQuestionDelete = (ids) => {
    questionService.deleteSelected(ids)
      .then(() => {
        listRef.current.reloadQuestions();
        setQuestion(undefined);
        formRef.current.reset();
      })
      .catch((err) => {
        alertService.error(`Błąd usuwania pytań. ${err}`);
      });
  };

    const tags = [
      { name: 'EASY', number: 51 },
      { name: 'WORLD', number: 12 },
      { name: 'POPULAR', number: 68 },
      { name: 'GEO', number: 20 },
  ]

  return (
    <Container fluid>
      <Row className="h-100">
        <Col>
          <Card>
            <Card.Body>
              <Row className="h-100">
                <Col lg={9}>
                  <QuestionList
                    ref={listRef}
                    selectQuestion={handleQuestionSelect}
                    deleteSelected={handleQuestionDelete}
                    setDefaultTags={setDefaultTags}
                  />
                </Col>
                <Col lg={3}>
                  <QuestionForm
                    formRef={formRef}
                    selected={selectedQuestion}
                    clean={() => { setQuestion(undefined); }}
                    saveQuestion={handleQuestionSave}
                    defaultTags={defaultTags}
                    setDefaultTags={setDefaultTags}
                  />
                  <Stats value={tags} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
