import { fetchWrapper} from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL+"/current-games";

export const currentGamesService = {
    getAll, 
}


function getAll() {
    return fetchWrapper.get(`${baseUrl}/getAll`);
}