import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import TopbarProfile from './TopbarProfile';
import TopbarLanguage from './TopbarLanguage';
import { Navbar, Nav, NavItem } from 'react-bootstrap';


class Topbar extends PureComponent {
  constructor() {
    super()
    this.state = {
      isOpen: false
    }
  }
  toggle = () => {
    console.log(this.state)
    this.setState({ isOpen: !this.state.isOpen })
  }
  render() {
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <Navbar
            bg="white"
            expand="sm"
            style={{ minHeight: 60 }}
          >
            <Navbar.Toggle
              aria-controls="navbar-collapse"
              style={{ margin: "2px 0" }}
            />
            <Navbar.Collapse id="navbar-collapse">
              <Nav className="mr-auto" navbar>
                <NavLink to="/games/" className="text-uppercase h6 nav-link">baza gier</NavLink>
                <NavLink to="/creator/" className="text-uppercase h6 nav-link">kreator</NavLink>
                <NavLink to="/questions/" className="text-uppercase h6 nav-link">baza pytań</NavLink>
                <NavLink to="/current-games" className="text-uppercase h6 nav-link">Trwające gry</NavLink>
                {/* <NavLink to="/library/" className="text-uppercase h6 nav-link">biblioteka</NavLink> */}
              </Nav>
            </Navbar.Collapse>

          </Navbar>
          <TopbarProfile />
        </div>
      </div>
    );
  }
}

export default Topbar;
