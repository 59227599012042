import React from 'react';
import style from './Tag.module.css';

function Tag (props) {
    return (
        <div className={style.tag}>
            {`#${props.value.name}: ${props.value.number}`}
        </div>
    )
}

export default Tag;