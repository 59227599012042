/* eslint-disable react/prop-types */
import { queryByDisplayValue } from '@testing-library/react';
import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { alertService,  } from '~root/_services';
import RoundSelect from './RoundSelect';

const GameForm = ({
  selected, clean, saveGame, formRef, roundList
}) => {

  const [points, setPoints] = useState(0);
  const [selectedRounds, setSelectedRounds] = useState([]);
  const categories = [ "#POLISH", "#WORLD", "#EASY" ];

  const handleSubmit = (e) => {
    e.preventDefault();

    // const selected_rounds = Array.from(e.currentTarget.elements.rounds.selectedOptions).map(({ value }) => value);


    if (selectedRounds.length === 0) {
      alertService.error("nie wybrano żadnej rundy")
      return;
    }

    const roundsToSend = [];
      for (let round of selectedRounds){
        roundsToSend.push(round.value)
      }

    const values = {
      name: e.currentTarget.elements.name.value,
      level: e.currentTarget.elements.level.value,
      winable_points: e.currentTarget.elements.winpoints.value,
      rank_points: e.currentTarget.elements.rank_points.value,
      rounds: roundsToSend,
      category: e.currentTarget.elements.category.value,
      studio: e.currentTarget.elements.studio.value,
    };
    saveGame(selected?.id, values); 

    e.currentTarget.reset();
  };


  return (
    <div>
      {selected?.id && <h3>EDYCJA {selected.id}</h3>}
      <Form onSubmit={handleSubmit} ref={formRef}>
        <FormInput
          name="name"
          description="Nazwa gry"
          value={selected?.name}
        />
        <FormInput
          name="level"
          description="Poziom trudności"
          value={selected?.level ? selected.level : '#EASY'}
          type="text"
          disabled={true}
        />
        <FormInput
          name="winpoints"
          description="Punkty do zdobycia (zależy od wybranych rund)"
          value={ points }
          type="number"
          disabled={true}
        />
        <FormInput
          name="rank_points"
          description="Punkty w rankingu"
          value={selected?.rank_points}
          type="number"
        />
        <FormSelectVariant
          name="category"
          description="Kategoria"
          options={categories}
          selected={ selected?.category }
        />
        <RoundSelect 
          description="Rundy"
          name="rounds" 
          value={selected?.rounds}
          selectedRounds={selectedRounds}
          setSelectedRounds={setSelectedRounds}
          setPoints={setPoints}
        />
        <FormSelectVariant
          name="studio"
          description="Wariant studia"
          options={['standardowe']}
          disabled
        />
        <Button variant="primary" type="submit">
          {selected ? 'Zapisz' : 'Dodaj grę'}
        </Button>
        {selected && (
          <Button variant="danger" type="reset" onClick={clean}>
            Wyczyść
          </Button>
        )}
      </Form>

    </div>
  );
};

const FormInput = ({
  name, description, value, withCheckbox, index, checked, handleRadioChange, type = 'text', disabled
}) => {

  return (
    <Form.Group>
      <Form.Label>
        {description}
      </Form.Label>
      <div className="d-flex flex-row">
        {withCheckbox && (
          <Form.Control
            type="radio"
            name="correct"
            className="w-auto mr-2"
            value={index}
            checked={checked}
            onChange={handleRadioChange}
          />
        )}
        
        {
          !disabled ? 
           <Form.Control type={type} name={name} required defaultValue={value} readOnly={disabled} />
           :
           <Form.Control type={type} name={name} required defaultValue={value} readOnly={disabled} value={value} />
        }
      </div>
    </Form.Group>
  );
} 

const FormSelectVariant = ({
  name, description, options, selected, disabled
}) => (
  <Form.Group>
    <Form.Label>
      {description}
    </Form.Label>
    <Form.Control disabled={disabled} as="select" name={name}>
      {options.map((item, index) => (
        <option 
          value={item}
          selected={ selected === item && true } 
          key={`option_${name}_${index}`}>{item} 
        </option>
      ))}
    </Form.Control>
  </Form.Group>
);

export default GameForm;
