import { fetchWrapper} from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL+"/games";

export const hostedGameService = {
    create,
    getAll, 
  
    update,
}


/**
 * Send post request to create new hosted-game in database
 * @param {obiect} params 
 * @returns server response
 */

function create(params) {
    return fetchWrapper.post(baseUrl, params);
}


/**
 * Send get request to get all Hosted-games from database
 * @returns server response
 */

function getAll() {
    return fetchWrapper.get(`${baseUrl}/hosted-games`);
}


/**
 * Send put request to update Hosted-game in database
 * @param {string} id 
 * @param {obiect} params 
 * @returns server response
 */

function update(id,params){
    console.log(params)
    return fetchWrapper.put(`${baseUrl}/${id}`, params)
}
