import React, { useRef, useState, useEffect } from 'react';
import {
  Container, Row, Col, Card,
} from 'react-bootstrap';
import { creatorGamesService, alertService, roundService } from '~root/_services';
import GameForm from './components/GameForm';
import GameList from './components/GameList';

const Game = () => {
  const [selectedGame, setGame] = useState(undefined)
  const [roundList, setRounds] = useState([])

  const listRef = useRef();
  const formRef = useRef();

  const getRoundList = () => {
    roundService.getAll()
      .then(res => {
        setRounds(res)
      })
      .catch(err => {
        alertService.error(err)
      })
  }

  useEffect(() => {
    getRoundList();
  }, [])

  const handleGameSelect = ({ id, name, rank_points, category, rounds }) => {
    if (selectedGame?.id === id) return;
    setGame({ id, name, rank_points, category, rounds })
  }

  const handleGameDelete = (ids) => {
    creatorGamesService.deleteSelected(ids)
      .then(() => {
        listRef.current.reloadGames();
        setGame(undefined);
        formRef.current.reset();
      })
      .catch((err) => {
        alertService.error(`Błąd usuwania gier. ${err}`);
      });
  }

  const handleGameSave = async (id, props) => {
    if (id) await creatorGamesService.update(id, props);
    else await creatorGamesService.create(props);

    setGame(undefined);

    listRef.current.reloadGames();

    return true;
  }

  return (
    <Container fluid>
      <Row className="h-100">
        <Col>
          <Card>
            <Card.Body>
              <Row className="h-100">
                <Col lg={9}>
                  <GameList
                    ref={listRef}
                    selectGame={handleGameSelect}
                    deleteSelected={handleGameDelete}
                  />
                </Col>
                <Col lg={3}>
                  <GameForm
                    formRef={formRef}
                    selected={selectedGame}
                    saveGame={handleGameSave}
                    clean={() => { setGame(undefined); }}
                    roundList = {roundList}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
};

export default Game;
