import React, { useEffect, useState, useRef } from 'react'
import Table from './components/Table';
import Form from './components/Form';
import {
    Container, Row, Col, Card
} from 'react-bootstrap';
import { alertService, gameService, hostedGameService } from '~root/_services';

export default function Active({ games, getGames, refreshAll }) {
    const [gameOptions, setGameOptions] = useState([])
    const [selected, setSelected] = useState(undefined)

    const gameSelected = (props) => {
        setSelected(props)
    }

    const handleSaveOrUpdate = async (id, values) => {
        try {
            if (id) await hostedGameService.update(id, values)
            else await hostedGameService.create(values)
        }
        catch (err) {
            alertService.error(err)
            return;
        }

        setSelected(undefined)
        getGames()

        return true;
    }

    const handleGameDelete = (ids) => {
        gameService.deleteSelected(ids)
        .then(res => {
            getGames();
        })
        .catch(err => {
            alertService.error("Wystąpił błąd podczas usuwania gier: "+err);
        })
    }


    return (
        <Container fluid>
            <Row className="h-100">
                <Col>
                    <Card>
                        <Card.Body>
                            <Row className="h-100">
                                <Col lg={9}>
                                    <Table
                                        data={games}
                                        handleRowClick={gameSelected}
                                        deleteSelected={handleGameDelete}
                                        reloadData={refreshAll}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Form
                                        selected={selected}
                                        clean={() => setSelected(undefined)}
                                        saveGame={handleSaveOrUpdate}
                                        gameOptions={gameOptions}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
