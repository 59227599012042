import React , {useEffect, useState} from "react"
import CurrentGame from "./CurrentGame"
import { currentGamesService, alertService } from '~root/_services';

export default function GamesContainer() {
    const [games, setGames] = useState([]);

    useEffect(() => {
        getGames();
        const getGamesInterval = setInterval(() => getGames(), 3000);
        return () => {
            clearInterval(getGamesInterval);
        }
    },[])

    function getGames() {
        currentGamesService.getAll()
        .then((res) => setGames(res))
        .catch(err => alertService.error("Error while fetching games",err))
    }

    return (
        <div>
            {
                games.map(game => {
                    return <CurrentGame {...game} key={games.indexOf(game)} />
                })
            }
        </div>
    )
}