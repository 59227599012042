import { BehaviorSubject } from 'rxjs';
import { fetchWrapper, history } from '~root/_helpers';
import { changeColorToBlue } from '~root/redux/actions/colorActions';
import store from '~root/containers/App/store'

const userSubject = new BehaviorSubject(null);
const baseUrl = process.env.REACT_APP_SERVER_URL+"/accounts";


export const accountService = {
    login,
    logout,
    refreshToken,
    register,
    verifyEmail,
    forgotPassword,
    validateResetToken,
    resetPassword,
    changePassword,
    getAll,
    getById,
    create,
    update,
    delete: _delete,
    user: userSubject.asObservable(),
    get userValue () { return userSubject.value }
};


/**
 * Login 
 * @param {string} email 
 * @param {string} password 
 * @param {string} lang 
 * @returns 
 */
function login(email, password, lang) {
    return fetchWrapper.post(`${baseUrl}/authenticate`, { email, password,lang})
        .then(user => {
            // publish user to subscribers and start timer to refresh token
            userSubject.next(user);
            startRefreshTokenTimer();
            return user;
        });
}


/**
 * Change password
 * @param {string} id 
 * @param {string} currentPassword 
 * @param {string} newPassword 
 * @param {string} newPasswordRepeat 
 * @param {string} lang 
 * @returns 
 */
function changePassword(id,currentPassword, newPassword, newPasswordRepeat,lang) {
    return fetchWrapper.put(`${baseUrl}/changePassword/${id}`, 
    {currentPassword:currentPassword, newPassword:newPassword, newPasswordRepeat:newPasswordRepeat,lang:lang}
    )
}


/**
 * Logout
 * @param {string} lang 
 */
function logout(lang) {
    // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
    fetchWrapper.post(`${baseUrl}/revoke-token`, {lang});
    stopRefreshTokenTimer();    
    userSubject.next(null);      
    store.dispatch(changeColorToBlue())
    history.push("/login")    
}


/**
 * Refresh token to logout user after time
 * @returns 
 */
function refreshToken() {
    
    return fetchWrapper.post(`${baseUrl}/refresh-token`, {})
        .then(user => {
            // publish user to subscribers and start timer to refresh token
            
            userSubject.next(user);
            startRefreshTokenTimer();
            return user;
        })
        .catch(err=>{

        })
}


/**
 * Registration
 * @param {object} params 
 * @param {string} lang 
 * @returns 
 */
function register(params,lang) {
    params.lang=lang
    return fetchWrapper.post(`${baseUrl}/register`, params);
}


/**
 * Email verification
 * @param {string} token 
 * @param {string} lang 
 * @returns 
 */
function verifyEmail(token,lang) {
    const params={token:token,lang:lang}
    return fetchWrapper.post(`${baseUrl}/verify-email`, params);
}


/**
 * Forgot password
 * @param {string} email 
 * @param {string} lang 
 * @returns 
 */
function forgotPassword(email,lang) {
    const params={email:email, lang:lang}
    return fetchWrapper.post(`${baseUrl}/forgot-password`, params);
}


/**
 * Reset password token validation
 * @param {string} token 
 * @param {string} lang 
 * @returns 
 */
function validateResetToken(token,lang) {
    const params={token:token, lang:lang}
    return fetchWrapper.post(`${baseUrl}/validate-reset-token`, params);
}


/**
 * Reset password
 * @param {string} token
 * @param {string} password
 * @param {string} confirmPassword
 * @param {string} lang 
 * @returns 
 */
function resetPassword({ token, password, confirmPassword },lang) {
    return fetchWrapper.post(`${baseUrl}/reset-password`, { token, password, confirmPassword,lang });
}


/**
 * Send post request to get all Users from database
 * @returns respond
 */
function getAll() {
    return fetchWrapper.get(`${baseUrl}`);
}


/**
 * Get user by id
 * @param {string} id 
 * @param {string} lang 
 * @returns respond
 */
function getById(id,lang) {
    return fetchWrapper.get(`${baseUrl}/${id}/${lang}`);
}


/**
 * Send post request to create new User in database
 * @param {object} params 
 * @param {string} lang 
 * @returns respond
 */
function create(params,lang) {
    params.lang=lang
    return fetchWrapper.post(baseUrl, params);
}


/**
 * Send put request to update User from database
 * @param {string} id 
 * @param {obiect} params 
 * @param {string} lang 
 * @returns respond
 */
function update(id, params,lang) {
    params.lang=lang
    if(id===userSubject.value.id && params.role==="User" && userSubject.value.role==="Admin"){
        throw new Error("error");
    }
    return fetchWrapper.put(`${baseUrl}/${id}`, params)
        .then(user => {
            // update stored user if the logged in user updated their own record
            if (user.id === userSubject.value.id) {
                // publish updated user to subscribers
                user = { ...userSubject.value, ...user };
                userSubject.next(user);
            }
            return user;
        });
}



/**
 * Send delete request to remove user from database
 * @param {string} id 
 * @returns respond
 */
// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`)
        .then(x => {
            // auto logout if the logged in user deleted their own record
            if (id === userSubject.value.id) {
                logout();
            }
            return x;
        });
}

// helper functions

let refreshTokenTimeout;

function startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(userSubject.value.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    refreshTokenTimeout = setTimeout(refreshToken, timeout);
}

function stopRefreshTokenTimer() {
    clearTimeout(refreshTokenTimeout);
}
