/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import Tags from '../../components/Tags/Tags';

const QuestionForm = ({
  selected, clean, saveQuestion, formRef , defaultTags, setDefaultTags
}) => {
  const [correct, setCorrect] = useState(0);

  const handleSubmit = (e) => {

    const tagsElementsArray = e.currentTarget.elements.hashtags.children[0];

    let tags = '';
    for (let i=0; i < tagsElementsArray.children.length - 1; i++) {
      tags += tagsElementsArray.children[i].firstChild.innerText+', ';
    }
    // console.log(tags)
    

    e.preventDefault();
    const values = {
      content: e.currentTarget.elements.question.value,
      answers: [
        {
          content: e.currentTarget.elements.answer1.value,
          correct: correct === 0,
        },
        {
          content: e.currentTarget.elements.answer2.value,
          correct: correct === 1,
        },
        {
          content: e.currentTarget.elements.answer3.value,
          correct: correct === 2,
        },
        {
          content: e.currentTarget.elements.answer4.value,
          correct: correct === 3,
        },
      ],
      hashtags: tags,
      // hashtags: e.currentTarget.elements.hashtags.value.trim(),
    };

    // console.log(values)

    saveQuestion(selected?.id, values);

    e.currentTarget.reset();
  };

  useEffect(() => {
    if (selected) {
      selected.answers.forEach((item, index) => {
        if (item.id === selected.correct_answer) {
          setCorrect(index);
        }
      });
    } else setCorrect(0);
  }, [selected]);

  return (
    <div>
      {selected?.id && <h3>EDYCJA {selected.id}</h3>}
      <Form onSubmit={handleSubmit} ref={formRef}>
        <FormInput name="question" description="Treść pytania" value={selected?.content} />

        <FormInput
          name="answer1"
          description="Odpowiedź 1"
          value={selected?.answers[0].content}
          withCheckbox
          index={0}
          checked={correct === 0}
          handleRadioChange={() => setCorrect(0)}
        />
        <FormInput
          name="answer2"
          description="Odpowiedź 2"
          value={selected?.answers[1].content}
          withCheckbox
          index={1}
          checked={correct === 1}
          handleRadioChange={() => setCorrect(1)}
        />
        <FormInput
          name="answer3"
          description="Odpowiedź 3"
          value={selected?.answers[2].content}
          withCheckbox
          index={2}
          checked={correct === 2}
          handleRadioChange={() => setCorrect(2)}
        />
        <FormInput
          name="answer4"
          description="Odpowiedź 4"
          value={selected?.answers[3].content}
          withCheckbox
          index={3}
          checked={correct === 3}
          handleRadioChange={() => setCorrect(3)}
        />

        <label for="hashtags"> Tagi </label>
        <fieldset id='hashtags' name='hashtags'>
          <Tags
           value={ selected?.hashtags ? selected.hashtags : []} 
           defaultTags={defaultTags} 
           setDefaultTags={setDefaultTags}
          />
        </fieldset>

        <Button variant="primary" type="submit">
          {selected ? 'Zapisz' : 'Dodaj pytanie'}
        </Button>
        {selected && (
        <Button variant="danger" type="reset" onClick={clean}>
          Wyczyść
        </Button>
        )}
      </Form>

    </div>
  );
};

const FormInput = ({
  name, description, value, withCheckbox, index, checked, handleRadioChange,
}) => (
  <Form.Group>
    <Form.Label>
      {description}
    </Form.Label>
    <div className="d-flex flex-row">
      {withCheckbox && (
      <Form.Control
        type="radio"
        name="correct"
        className="w-auto mr-2"
        value={index}
        checked={checked}
        onChange={handleRadioChange}
      />
      )}
      <Form.Control type="text" name={name} required defaultValue={value} />
    </div>
  </Form.Group>
);

export default QuestionForm;
