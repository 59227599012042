import React, { useEffect, useState } from "react";
import Select from "react-select"
import { Form } from 'react-bootstrap';
import { alertService, roundService } from "../../../../../_services";

export default function RoundSelect({ name, description, selectedRounds, setSelectedRounds, value, setPoints }) {
    const [options, setOptions] = useState();
    const [fullRounds, setFullRounds] = useState([]);

    useEffect(() => {
        roundService.getAll()
        .then(res => {
            const roundsArray = [];
            const roundsWithFullData = [];
                for (const round of res) {
                    const formatedRound = { label: round.name, value: round.id };
                    roundsArray.push(formatedRound);
                    roundsWithFullData.push(round)
                }
            setOptions(roundsArray);
            setFullRounds(roundsWithFullData)
        })
        .catch( err => alertService.error(`Błąd podczas pobierania dostępnych rund: ${err}`))
    }, []);

    useEffect(() => {
        checkIfSelected(value);
    }, [value])

    const handleChange = (value) => {
        setSelectedRounds(value)
        setSumaryPoints(value)
    }

    function setSumaryPoints(selectedValues){
        let points = 0;
            if( selectedValues ) {
                for (const fullRound of fullRounds) {
                    for (const selectedRound of selectedValues) {
                        if (fullRound.id === selectedRound.value)  {
                            points += fullRound.points;
                        }
                    }
                }
                setPoints(points)
            }
    }

    function checkIfSelected(value) {
        const roundsToSet = [];
        if(value) {
            for (const roundInGame of value) {
                for (const option of options) {
                    if (option.value === roundInGame) {
                        roundsToSet.push(option)
                    }
                }
            }
        }
        setSelectedRounds(roundsToSet);
        setSumaryPoints(roundsToSet);
    }

    return (
        <Form.Group style={{ marginBottom: 10}}>
            <Form.Label style={{ marginBottom: 7}}> 
                {description}
            </Form.Label>
            <Select 
                id="sel"
                name={name}
                options={options} 
                placeholder="Znajdź rundę..."
                isClearable={true}
                onChange={handleChange}
                closeMenuOnSelect={false}
                isMulti
                value={selectedRounds}
            />
        </Form.Group>
    )
}