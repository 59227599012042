import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import Layout from '~root/containers/Layout/index';
import MainWrapper from './MainWrapper';
import { Role } from '~root/_helpers'
import LogIn from '~root/containers/accounts/LogIn/index';
import { accountService } from '~root/_services';
import { PrivateRoute } from '~root/containers/PrivateRoute';
import resetPassw from '~root/containers/accounts/resetPassw/index'
import forgetPassw from '~root/containers/accounts/forgetPassw/index'
import verifyEmail from '~root/containers/accounts/verifyEmail/index';
import { Alert } from '~root/containers/Alert'
import AdminUsers from '~root/containers/admin/index'
import MainPage from '~root/containers/MainPage/index'
import Rooms from '~root/containers/Game/Rooms'
import UserJoin from '~root/containers/Game/components/UserJoin'
import ChangePassword from '~root/containers/accounts/ChangePassword'
import Privacy from './Privacy'

import {Creator, GameDatabase, Library, QuestionsDatabase} from '../routes'
import CurrentGames from '../routes/CurrentGames/CurrentGames';

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/rooms/:id?" component={Rooms} />
      <Route path="/join-game/:id" component={UserJoin} />
      <Route path="/adminUsers" roles={[Role.Admin]} component={AdminUsers} />
      <Route path="/changePassword" component={ChangePassword} />

      <Route exact path="/questions" component={QuestionsDatabase} />
      <Route exact path="/creator" component={Creator} />
      <Route exact path="/games" component={GameDatabase} />
      <Route exact path="/library" component={Library} />
      <Route exact path="/current-games" component={CurrentGames} />

      <Route exact path="/" component={MainPage} />

    </div>
  </div>
)



const wrappedMainpageUser = () => (
  <div>
    <Layout />
    <div className="container__wrap" style={{ "paddingLeft": "0" }}>
      <Route exact path="/" component={MainPage} />
    </div>
  </div>
)



const Router = ({history}) => {
  const { pathname } = useLocation();
  const [user, setUser] = useState({});

  useEffect(() => {
    const subscription = accountService.user.subscribe(x => setUser(x));
    return subscription.unsubscribe;
  }, []);

  return (
    <MainWrapper>

      <main>
        <Alert />
        <Switch>
        <Route path="/privacy" component={Privacy}/>

          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
          {user ? <Redirect from="/login" to="/" /> : null}
          {/* {user ? <Redirect from="/register" to="/" /> : null} */}

          <Route path="/login" component={LogIn} />
          {/* <Route path="/register" component={Register} /> */}
          <Route path="/resetPassword" component={resetPassw} />
          <Route path="/verifyEmail" component={verifyEmail} />
          <Route path="/forgetPassword" component={forgetPassw} />
          <PrivateRoute path="/changePassword" component={wrappedRoutes} />

          <PrivateRoute path="/rooms/:id?" component={wrappedRoutes} />
          <Route path="/join-game/:id" component={wrappedRoutes} />

          <PrivateRoute exact path="/questions" component={wrappedRoutes} />
          <PrivateRoute exact path="/games" component={wrappedRoutes} />
          <PrivateRoute exact path="/creator" component={wrappedRoutes} />
          <PrivateRoute exact path="/library" component={wrappedRoutes} />
          <PrivateRoute exact path="/current-games" component={wrappedRoutes} />

          <PrivateRoute path="/adminUsers" roles={[Role.Admin]} component={wrappedRoutes} />

          {user?
            <Redirect from="/" to="/games"/>
            :
            <Redirect from="/games" to="/"/>
          }

          {user && user.role === "User" ?
            <PrivateRoute exact path="/" component={wrappedMainpageUser} />
            :
            <PrivateRoute exact path="/" component={wrappedRoutes} />
          }
          <Redirect from="*" to="/" />
        </Switch>

      </main>
    </MainWrapper>
  )
}

export default Router;
