import React from 'react';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";


export default function Tags( { value, defaultTags, setDefaultTags } ) {


    function setTags(newTags) {
        for (let i=0; i<newTags.length; i++) {
            newTags[i] = newTags[i].toUpperCase();
        }
            
        console.log(newTags)
        setDefaultTags(newTags);
    }
    
    return (
        <ReactTagInput 
            placeholder="Wpisz tag i naciśnij enter"
            tags={ defaultTags[0] ? defaultTags : value  }
            onChange={(newTags) =>  setTags(newTags)}
        />
    )
}