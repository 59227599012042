import { fetchWrapper} from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL+"/games";

export const gameService = {
    create,
    getAll,
    getById,
    update,
    getIds,
    startStopGame,
    deleteSelected,
    getAllDisabled,
    changeStatus,
};


/**
 * Send post request to create new Game in database
 * @param {obiect} params 
 * @returns respond
 */

function create(params) {
    return fetchWrapper.post(baseUrl, params);
}


/**
 * Send get request to get all Games from database
 * @returns respond
 */

function getAll() {
    return fetchWrapper.get(baseUrl);
}



/**
 * 
 * @returns 
 */

function getIds() {
    return fetchWrapper.get(`${baseUrl}/ids`);
}


/**
 * Send get request to get game form database
 * @param {string} game_id 
 * @returns respond
 */

function getById(game_id) {
    return fetchWrapper.get(`${baseUrl}/get-one-game/${game_id}`);
}


/**
 * Send put request to update Game in database
 * @param {string} id 
 * @param {obiect} params 
 * @returns respond
 */

function update(id,params){
    return fetchWrapper.put(`${baseUrl}/${id}`, params)
}


/**
 * Send put request to change Game status in database
 * @param {string} id 
 * @returns 
 */
function startStopGame(id){
    return fetchWrapper.put(`${baseUrl}/startStopGame/${id}`)
}

function deleteSelected(ids){
    return fetchWrapper.delete(baseUrl, ids)
}

function getAllDisabled() {
    return fetchWrapper.get(baseUrl+"/get-all-disabled");
}

function changeStatus(id) {
    return fetchWrapper.put(baseUrl+"/change-status", {id})
}