import React, {useState} from "react";
import style from "../styles/CurrentGame.module.css";

export default function CurrentGame({ name, status, players, maxPlayers, time, rankPoints, createTime }) {

    function setStatus() {
        switch(status) {
            case "waiting": return { text: "OCZEKUJE", color: "#ff9900"}
            case "started": return { text: "ROZPOCZĘTA", color: "#33cc33"}
            case "finished": return { text: "ZAKOŃCZONA", color: "#808080"}
        }
        return { text: "ERROR", color: "black"}
    }

    function createGameTime() {
        let fullTime = time;
        let secounds = fullTime % 60;
        let minutes = parseInt(fullTime/60);
        let timer = ''; 
            if (minutes > 0) timer += `${minutes}m`; 
            if (secounds > 0) timer += ` ${secounds}s`;
        return timer;
    }

    function setCreateTime(createTime) {
        const date = new Date(createTime);

            const configureNumber = (time) => {
                const month = time;
                return month > 9 ? month : `0${month}`;
            }

        return `${configureNumber(date.getDate())}.${configureNumber(date.getMonth()+1)}.${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}`;
    }

    return (
        <div className={[style.container]} >
            <div className={style.name}> {name} </div>
            <div className={style.status} style={{ backgroundColor: setStatus().color}}>{setStatus().text}</div>
           
            <div className={style.iconContainer}>
                <img className={style.icon} src="img/user.png" />
                <div className={style.iconText}>{ players }/{ maxPlayers }</div>
            </div>

            <div className={style.iconContainer}>
                <img className={style.icon} src="img/timer.png" />
                <div className={style.iconText}>{ createGameTime() }</div>
            </div>

            <div className={style.iconContainer}>
                <img className={style.icon} src="img/star.png" />
                <div className={style.iconText}>{ rankPoints }</div>
            </div>

            <div className={style.iconContainer} style={{ width: 200}}>
                <img className={style.icon} src="img/writing.png" />
                <div className={style.iconText}> { setCreateTime(createTime) }</div>
            </div>

        </div>
    )
}