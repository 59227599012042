import { mdiAlertPlusOutline } from '@mdi/js';
import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import {
    Container, Row, Col, Card,
} from 'react-bootstrap';
import { alertService, prizeService } from '~root/_services';
import Form from './components/Form'
import Table from './components/Table'

export default function Prize() {
    const [prizes, setPrizes] = useState({ game: [], material: [] })
    const [selectedMaterial, setSelectedMaterial] = useState(undefined)
    const [selectedGame, setSelectedGame] = useState(undefined)
    const listRef = useRef();
    const formRef = useRef();

    const getPrizes = () => {
        prizeService.getAll()
            .then(res => {
                console.log(res)
                let model = {
                    material: res.prize_material,
                    game: res.prize_game,
                }
               setPrizes(model)
            })
            .catch(err => {
                alertService.error(err)
            })
    }

    const handlePrizeDelete = (ids) => {
        if (window.confirm(`Na pewno chcesz usunąć nagrody w liczbie ${ids.length} `)){
            prizeService.deleteSelected(ids)
            .then(() => {
              // setPrizes(undefined);
              getPrizes()
            })
            .catch((err) => {
              alertService.error(`Błąd usuwania nagród. ${err}`);
            });
        }
      }

    const handlePrizeSave = async (id, props, type) => {
        Object.assign(props, { type })

        try {
            if (id) await prizeService.update(id, props.icon, {icon:undefined, ...props});
            else await prizeService.create(props.icon, {icon:undefined, ...props});
        }
        catch (err) {
            alertService.error("An error occurred while saving prize! "+err)
        }


        type === 'game' ? setSelectedGame(undefined) : setSelectedMaterial(undefined);

        // listRef.current.reloadGames();
        getPrizes()

        return true;
    }

    useEffect(() => {
        getPrizes();
    }, [])

    return (
        <Container fluid>
            <Row className="h-100">
                <Col>
                    <Card style={{minHeight:"100%", height:"max-content"}}>
                        <Card.Body>
                            <h3>Nagrody rzeczowe</h3>
                            <Row className="h-50">
                                <Col lg={6}>
                                    <Table
                                        type={1}
                                        data={prizes.material}
                                        handleRowClick={setSelectedMaterial}
                                        deleteSelected={handlePrizeDelete}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Form
                                        type={1}
                                        withPoints
                                        selected={selectedMaterial}
                                        clean={() => setSelectedMaterial(undefined)}
                                        savePrize={(id, props) => handlePrizeSave(id, props, "material")}
                                    />
                                </Col>
                                <Col lg={3}>

                                </Col>
                            </Row>
                            <div style={{ height: 50 }} className="border-top"></div>
                            <h3>Nagrody w grze</h3>
                            <Row className="h-50">
                                <Col lg={6}>
                                    <Table
                                        type={2}
                                        data={prizes.game}
                                        handleRowClick={setSelectedGame}
                                        deleteSelected={handlePrizeDelete}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Form
                                        type={2}
                                        selected={selectedGame}
                                        clean={() => setSelectedGame(undefined)}
                                        savePrize={(id, props) => handlePrizeSave(id, props, "game")}
                                    />
                                </Col>
                                <Col lg={3}>

                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
