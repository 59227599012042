import React, { Component } from "react";
import { Card, CardBody, Col, Row, Container } from "reactstrap";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import $ from "jquery";
import RoomForm from "./components/RoomForm";
import {
  alertService,
  accountService,
  gameService,
  roundService,
  categoryService,
  challengeService
} from "~root/_services";
import RoomCard from "./components/RoomCard";
import SelectedRoom from "./components/SelectedRoom";
import RoundForm from "./components/Rounds/RoundForm";
import Rounds from "./components/Rounds/Rounds";

class Rooms extends Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      create: true,
      update: false,
      rooms: [],
      current_game: null,
      selected_room: null,
      btn_disabled: false,
      showUpload: false,
      round: false,
      rounds: [],
      current_round: null,
      categories: [],
      challenges: []
    };
  }

  componentDidMount = async () => {
    this.setState({ lang: this.props.i18n.language });
    this.getRooms();
    this.getCategories();
    this.getChallenges();
  };

  getChallenges = () => {
    challengeService.get()
      .then(res => {
        this.setState({ challenges: res })
      })
      .catch(err => {
        alertService.error(err)
      })
  };

  getCategories = () => {
    categoryService
      .getCategories()
      .then((res) => {
        this.setState({ categories: res });
      })
      .catch((err) => {
        alertService.error(err);
      });
  };

  componentDidUpdate = () => {
    if (this.state.lang && this.props.i18n.language !== this.state.lang) {
      this.setState({ lang: this.props.i18n.language });
    }
  };

  changeModalVisibility = () => {
    this.setState({
      visible: !this.state.visible,
      create: true,
      update: false,
      showUpload: false,
      current_game: null,
      round: false,
      current_round: null,
    });
  };

  getRooms = async () => {
    const ref = this;
    const rooms = await gameService.getAll(accountService.userValue.id);

    let rooms_arr = rooms.map((item, index) => {
      return (
        <Col
          key={"col" + index}
          md={4}
          xl={3}
          sm={6}
          onClick={(e) => {
            if (
              e.target.tagName.toLowerCase() !== "svg" &&
              e.target.tagName.toLowerCase() !== "path"
            ) {
              ref.setState({ selected_room: item }, () => { });
              ref.props.history.push("/rooms/" + item.gameID);
              this.getRounds(item.gameID);
            } else e.preventDefault();
          }}
        >
          <RoomCard
            title={item.gameID}
            id={item.gameID}
            key={"room" + index}
            editRoom={this.editRoom}
          />
        </Col>
      );
    });
    this.setState({ rooms: rooms_arr });

    if (rooms.length > 0 && !this.state.selected_room) {
      this.props.id
        ? this.setState(
          {
            selected_room: rooms.filter((item) => {
              return item.gameID === this.props.id;
            })[0],
          },
          () => {
            ref.props.history.push(
              "/rooms/" + this.state.selected_room.gameID
            );
            this.getRounds(this.state.selected_room.gameID);
          }
        )
        : this.setState({ selected_room: rooms[0] }, () => {
          ref.props.history.push("/rooms/" + rooms[0].gameID);
          this.getRounds(rooms[0].gameID);
        });
    }
  };

  getRounds = (id) => {
    roundService
      .getRounds(id)
      .then((res) => {
        this.setState({ rounds: res });
      })
      .catch((err) => {
        alertService.error(err);
      });
  };

  handleSubmit = async (fields) => {
    let submit = true;
    if (this.state.current_game === null) {
      fields.userID = accountService.userValue.id;
      await gameService.create(fields).catch((error) => {
        submit = false;
        alertService.error(error);
      });
    } else {
      fields.userID = this.state.current_game.userID;
      await gameService
        .update(fields, this.state.current_game.gameID)
        .catch((error) => {
          submit = false;
          alertService.error(error);
        });
    }
    if (submit) {
      this.setState({ visible: false, current_game: null });
      this.getRooms();
    }
  };

  handleShowUpload = (val) => {
    this.setState({ showUpload: val });
  };

  editRoom = async (id) => {
    const data = await gameService.getById(id);
    this.setState({
      current_game: data,
      visible: true,
      create: false,
      update: true,
    });
  };

  deleteRound = (id) => {
    roundService
      .remove(id)
      .then(() => {
        this.getRounds(this.props.match.params.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { t } = this.props;
    let klasa = "";
    if (this.state.visible) {
      $("body").addClass("modal-open");
      klasa = "show";
    } else {
      klasa = "";
      $("body").removeClass("modal-open");
    }
    return (
      <Container className="dashboard">
        <div>
          {this.state.selected_room ? (
            <Row>
              <SelectedRoom
                room={this.state.selected_room}
                disabled={this.state.btn_disabled}
                startStopGame={this.handleStartStopGame}
              />
            </Row>
          ) : null}
          <Row>
            <div style={{ width: "100%" }}>
              <div className="d-flex flex-row flex-wrap">
                {this.state.rooms}
                <Col md={4} xl={3} sm={6} onClick={this.changeModalVisibility}>
                  <Card>
                    <CardBody className="room-card-container">
                      <div className="room-card">
                        <h3 style={{ overflowWrap: "normal" }}>
                          {this.props.t("conference.new-room")}
                        </h3>
                        <FontAwesomeIcon
                          className="icon-add room-icon"
                          icon={faPlusSquare}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </div>
              <div
                className={`modal fade ${klasa}`}
                id="createRoomModal"
                tabIndex="-1"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content text-center">
                    <div
                      className="close-icon"
                      onClick={this.changeModalVisibility}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                    <div className="modal-body">
                      <div className="card-body p-sm-6">
                        <div className="card-title">
                          {this.state.create && !this.state.update ? (
                            <h3 className="create-only">
                              {this.state.round
                                ? t("conference.new-round")
                                : t("conference.new-room")}
                            </h3>
                          ) : (
                            <h3 className="update-only">
                              {this.state.round
                                ? t("conference.round-settings")
                                : t("conference.room-settings")}
                            </h3>
                          )}
                        </div>
                        {this.state.round ? (
                          <RoundForm
                            gameID={this.state.selected_room.gameID}
                            create={this.state.create}
                            update={this.state.update}
                            visible={this.state.visible}
                            closeModal={this.changeModalVisibility}
                            getRounds={this.getRounds}
                            current_round={this.state.current_round}
                            categories={this.state.categories}
                          />
                        ) : (
                          <RoomForm
                            create={this.state.create}
                            update={this.state.update}
                            handleSubmit={this.handleSubmit}
                            visible={this.state.visible}
                            current={this.state.current_game}
                            showUpload={this.state.showUpload}
                            handleShowUpload={this.handleShowUpload}
                            challenges={this.state.challenges}
                          />
                        )}
                      </div>
                      <div className="card-footer">
                        {this.state.create && !this.state.update ? (
                          <p className="create-only">
                            {this.state.round
                              ? t("round.can-delete")
                              : t("conference.can-delete")}
                          </p>
                        ) : (
                          <p className="update-only">
                            {this.state.round
                              ? t("round.can-adjust")
                              : t("conference.can-adjust")}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <button
                    className="btn btn-orange"
                    onClick={() => {
                      this.setState({
                        round: true,
                        visible: true,
                        create: true,
                        update: false,
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      className="icon-add game-icon"
                      icon={faPlusSquare}
                    />
                    {t("game.add-round")}
                  </button>
                  <h4 className="mb-3">{t("game.rounds")}:</h4>
                  <Rounds
                    rounds={this.state.rounds}
                    deleteRound={this.deleteRound}
                    editRound={(round) => {
                      this.setState({
                        current_round: round,
                        round: true,
                        visible: true,
                        create: false,
                        update: true,
                      });
                    }}
                    history={this.props.history}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default withTranslation("common")(Rooms);
