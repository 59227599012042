import Select from "react-select"
import React, {useEffect, useState} from "react";
import {creatorGamesService} from "~root/_services";
import { Form } from 'react-bootstrap';
import { stringify } from "uuid";

export default function SearchSelectInput({ name, description, selected }) {
    const [games, setGames] = useState([]);
    const [selectedOption, setSelectedOption] = useState();

    useEffect(() => {
        creatorGamesService.getAll().then(res => {
            const options = [];
            res.forEach(game => {
                const newOption = {
                    value: game._id,
                    label: game.name,
                }
                game._id === selected && setSelectedOption(game.name);
                options.push(newOption);
            });
            setGames(options);
        }).catch(err => console.log("Failed to get games"))
    }, [selected]);


    function handleChange(option) {
        setSelectedOption(option)
    }

    
    function setLabel(selected) {
        let newLabel;
        games.forEach(game => {
            if (game.value === selected) {
                newLabel = game.label
            }
        });
        console.log({ value: selected, label: newLabel})
        return newLabel;
    }

    return (
        <Form.Group style={{ marginBottom: 10}}>
            <Form.Label style={{ marginBottom: 7}}> 
                {description} 
            </Form.Label>
            <Select 
                id="sel"
                name={name}
                options={games} 
                placeholder="Znajdź grę..."
                isClearable={true}
                value={selectedOption}
                onChange={handleChange}
            />
        </Form.Group>
    )
}