import React from 'react';
import Tag from './components/Tag';
import style from './QuestionStats.module.css';
import { alertService, questionService } from '~root/_services';

function Stats (props) {

    const [tags, setTags] = React.useState([]);

    function mySort(a,b) {
        return b.number - a.number;
    }

    const getStats = () => {
        questionService.getAll()
          .then((res) => {
            console.log("RESPONSE", res)
              const tagsArray = [];
                res.forEach(element => {
                  element.hashtags.forEach( tag => {
                      let parts = tag.split(', ');
                        for (let i=0; i<parts.length-1; i++) {
                            tagsArray.push(tag.split(', ')[i])
                        }
                  })
                });

                const counts = {};
                tagsArray.forEach((x) => {
                    counts[x] = (counts[x] || 0) +1;
                });
                const newTagsStats = [];

                for( const [key, value] of Object.entries(counts)) {
                    newTagsStats.push({name: key, number: value});
                }
                newTagsStats.sort(mySort);
                console.log(newTagsStats)
                setTags(newTagsStats);
          })
          .catch((err) => {
            alertService.error(`Nie udało się pobrać statystyk. ${err}`);
          });
      };

      React.useEffect(() => {
        getStats();
      }, []);

    return(
        <div className={style.stats}>
            <div className={style.title}> Statystyki </div>
            <div> Pytania </div>
            <div className={style.content}>
              { tags.map(tag => 
                  <Tag value={tag}/>
              ) }
            </div>
            
        </div>
    )
}

export default Stats;