/* eslint-disable camelcase */
import common_en from './en/common.json';
import common_pl from './pl/common.json';

export default {
  en: {
    common: common_en,
  },
  pl: {
    common: common_pl,
  },
};
