import React, { useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Button, Badge } from 'react-bootstrap';

const columns = [
  {
    field: 'type',
    headerName: '',
    width: 50,
    renderCell: () => (
      <div style={{  width: "20px" }}>
        <img src="img/gift.png" width="100%" />
      </div>
    )
  },
  {
    field: 'description',
    headerName: 'Nagroda',
    editable: false,
    width: 350,
  },
  { field: 'points', headerName: 'Punkty', width: 150 },
  
  // {
  //   field: 'icon',
  //   headerName: 'Ikona',
  //   width: 150,
  //   renderCell: (cell) => cell.value && (
  //     <img
  //       src={`${process.env.REACT_APP_SERVER_URL}/file/${cell.value}`}
  //     />
  //   )
  // },
  { field: 'language', headerName: 'Język', width: 200 },
];


export default function Table({ data, handleRowClick, deleteSelected, type }) {
  const [selectedIds, setSelection] = useState([]);

  return (
    <div style={{ height: '100%', width: '100%', minHeight: 200 }}>
      <DataGrid
        rows={data}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        onRowClick={(props) => {
          handleRowClick(props.row)
        }}
        onSelectionModelChange={(props) => {
          setSelection(props.selectionModel);
        }}
        components={{
          Footer: () => {
            if (selectedIds.length > 0) {
              return (
                <Button
                  variant="danger"
                  type="button"
                  className="ml-3"
                  onClick={() => {
                    deleteSelected(selectedIds);
                    setSelection([]);
                  }}
                >
                  USUŃ ZAZNACZONE
                </Button>
              );
            }
            return null;
          },
        }}
      />
    </div>
  )
}
