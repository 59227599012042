import { fetchWrapper} from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL+"/prizes";

export const prizeService = {
    create,
    update,
    getAll,
    deleteSelected
};


/**
 * Send post request to create new Prize in database
 * @param {file} icon 
 * @param {obiect} params 
 * @returns respond
 */
function create(icon, params) {
    return fetchWrapper.postWithFile(baseUrl,icon, params);
}

/**
 * Send put request to update Prize in database
 * @param {string} id 
 * @param {file} icon 
 * @param {obiect} params 
 * @returns respond
 */
function update(id,icon, params) {
    return fetchWrapper.postWithFile(`${baseUrl}/${id}`,icon, params);
}

/**
 * Send get request to all prizes from database
 * @returns respond
 */
function getAll() {
    return fetchWrapper.get(`${baseUrl}/getAll`);
}


/**
 * Send delete request to delete prizes from database
 * @param {array} ids 
 * @returns respond
 */
function deleteSelected(ids){
    return fetchWrapper.delete(baseUrl, ids)
  }