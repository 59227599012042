import React, {useState, useEffect} from 'react'
import { Container, Row, Col, Card, Tab, Tabs } from 'react-bootstrap'
import Active from './Active'
import Disabled from './Disabled'
import { hostedGameService, gameService, alertService} from "~root/_services"

export default function GameDatabase() {
    const [activeGames, setActiveGames] = useState([]);
    const [disabledGames, setDisabledGames] = useState([]);

    useEffect(() => {
        refreshAll()
    },[])

    const getActiveGames = async () => {
        await hostedGameService.getAll()
            .then(res => {
                setActiveGames(res)
            })
            .catch(err => {
                alertService.error("Nie udało się pobrać aktywnych gier")
            })
    }

    const getDisabledGames = async () => {
        await gameService.getAllDisabled()
            .then(res => {
                setDisabledGames(res)
            })
            .catch(err => {
                alertService.error("Nie udało się pobrać zablokowanych gier")
            })
    }

    function refreshAll() {
        getActiveGames();
        getDisabledGames();
    }

    return (
        <Container fluid>
            <Row className="h-100">
                <Col>
                    <Card>
                        <Card.Body>
                            <Tabs>
                                <Tab eventKey="active" title="Aktywne" tabClassName="h6">
                                    <Active games={activeGames} getGames={getActiveGames} refreshAll={refreshAll}/>
                                </Tab>
                                <Tab eventKey="disabled" title="Zablokowane" tabClassName="h6">
                                    <Disabled games={disabledGames} getGames={getDisabledGames} refreshAll={refreshAll}/>
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
