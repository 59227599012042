import React, { Component } from 'react'
import { Col, Card, CardBody } from 'reactstrap'
import Form from 'react-bootstrap/Form'
import { withTranslation } from 'react-i18next'
import { gameService } from '~root/_services'
class SelectedRoom extends Component {
    startGame = () => {
        gameService.startStopGame(this.props.room.gameID)
    }

    linkAction = (id) => {
        const link = `${process.env.REACT_APP_SERVER_URL}/join-game/${id}`
        navigator.clipboard.writeText(link)
    }

    render() {
        const { t } = this.props
        return (
            <Col sm={12}>
                <Card>
                    <CardBody>
                        <div className="big-room-card">
                            <h1 style={{ "overflowWrap": "normal" }}>{this.props.room.gameID}</h1>
                        </div>
                        {/* Link to join */}
                        <div className="mb-3 mt-3">
                            <Form.Label>{this.props.t('game.user-link')}: </Form.Label>
                            <div className="d-flex flex-row align-items-center flex-wrap mb-2">
                                <Form.Control ref={(input) => this.input = input} className="col-4" style={{ "marginRight": "10px" }} type="text" disabled={true}
                                    value={`${process.env.REACT_APP_SERVER_URL}/join-game/${this.props.room.user_join_id}`} />

                                <Button
                                    classes="btn btn-orange"
                                    style={{ "margin": "0px" }}
                                    onClick={() => {
                                        this.linkAction(this.props.room.user_join_id)
                                    }}
                                    content={t('roomform.copy')}
                                />
                            </div>
                        </div>

                        <div><h3>{this.props.t('game.or')}</h3></div>

                        {/* Code to join */}
                        <div className="mb-3 mt-3">
                            <Form.Label>Link dla moderatora: </Form.Label>
                            <div className="d-flex flex-row align-items-center flex-wrap mb-2">

                                <Form.Control
                                    ref={(input) => this.input = input}
                                    className="col-4"
                                    style={{ "marginRight": "10px" }}
                                    type="text"
                                    disabled={true}
                                    value={`${process.env.REACT_APP_SERVER_URL}/join-game/${this.props.room.mod_join_id}`}
                                />
                                <Button
                                    classes="btn btn-orange"
                                    style={{ "margin": "0px" }}
                                    onClick={() => {
                                        this.linkAction(this.props.room.mod_join_id)
                                    }}
                                    content={t('roomform.copy')}
                                />
                            </div>
                        </div>
                        <Button
                            classes="btn btn-orange"
                            style={{ "display": "none" }}
                            onClick={this.startGame}
                            content="Rozpocznij grę"
                        />
                    </CardBody>
                </Card>
            </Col>
        )
    }
}

const Button = ({ classes, style, onClick, content }) => (
    <button className={classes} style={style} onClick={onClick}>
        {content}
    </button>
)

export default (withTranslation('common'))(SelectedRoom)
