/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker'
import { alertService } from '~root/_services';
import SearchSelectInput from './SearchSelectInput';

const GameForm = ({
    selected, clean, saveGame, gameOptions
}) => {
    const [startDate, setStartDate] = useState(undefined);

    const handleSubmit = (e) => {
        // console.log(e.currentTarget.elements.award.selectedOptions[0].value)
        e.preventDefault();

        if (typeof startDate === "undefined") {
            alertService.error("Wybierz poprawną datę")
            return;
        }

        const values = {
            game: document.getElementById("sel").children[1].value,
            name: e.currentTarget.elements.name.value,
            table: e.currentTarget.elements.table.selectedOptions[0].value,
            availability: e.currentTarget.elements.availability.selectedOptions[0].value,
            active: e.currentTarget.elements.active.selectedOptions[0].value,
            award: e.currentTarget.elements.award.selectedOptions[0].value,
            start_type: e.currentTarget.elements.start_type.selectedOptions[0].value,
            starts: startDate,
            host: e.currentTarget.elements.host.selectedOptions[0].value,
            entry:e.currentTarget.elements.entry.selectedOptions[0].value,
            min_players: e.currentTarget.elements.min_players.value,
            max_players: e.currentTarget.elements.max_players.value,
            second_screen: e.currentTarget.elements.second_screen.selectedOptions[0].value,
        };

        console.log(values)

        saveGame(selected?.id, values);

        e.currentTarget.reset();
    };

    useEffect(() => {
        selected ? setStartDate(new Date(selected.starts)) : setStartDate(undefined)
    }, [selected]);

    return (
        <div>
            {selected?.id && <h3>EDYCJA {selected.id}</h3>}
            <Form onSubmit={handleSubmit}>
                {/* <FormSelect
                    name="game"
                    description="Gra"
                    options={gameOptions}
                    selected={selected?.game}
                /> */}
                <FormInput
                    name="name"
                    description="Nazwa gry"
                    value={selected?.name}
                />
                <SearchSelectInput 
                    name="game"
                    description="Gra"
                    selected={selected?.game}
                />
                <FormSelect
                    name="table"
                    description="Rodzaj gry"
                    options={[{ value: 'best', name: "BEST" }, { value: 'friends', name: "FRIENDS" }, { value: 'vip', name: "VIP" }]}
                    selected={selected?.table}
                />
                <FormSelect
                    name="availability"
                    description="Dostępność"
                    options={[{value: 'always', name:'Zawsze'}]}
                    selected={selected?.availability}
                />
                
                <FormSelect
                    name="active"
                    description="Aktywna"
                    options={[{ value: true, name: "YES" }, { value: false, name: "NO" }]}
                    selected={selected?.active}
                />
                <FormSelect
                    name="award"
                    description="Nagroda"
                    options={[{value: 'avatar-groom', name:'Awatar gromu'}]}
                    selected={selected?.award}
                />
                <FormSelect
                    name="start_type"
                    description="Grę rozpoczyna"
                    options={[
                        { value: 'HOST', name:'Gospodarz'},
                        { value: 'MAXPLAYERS', name:'Użytkownicy'},
                        { value: 'DATETIME', name:'Czas'},
                    ]}
                    selected={selected?.award}
                />
                <Form.Group>
                    <Form.Label>Data rozpoczęcia</Form.Label>
                    <div>
                        <DateTimePicker value={startDate} onChange={setStartDate} />
                    </div>
                </Form.Group>

                <FormSelect
                    name="host"
                    description="Host gry - NOT IMPLEMENTED YET"
                    options={[ 
                        { value: 'all',  name:'Każdy'},
                        { value: 'users', name:'Użytkownicy'},
                        { value: 'premium', name:'Użytkownicy Premium'},
                        { value: 'hpa', name:'HPA'},
                     ]}
                />

                <FormSelect
                    name="entry"
                    description="Uczestnicy - NOT IMPLEMENTED YET"
                    options={[ 
                        {  value: 'all',  name:'Każdy'},
                        {  value: 'users', name:'Użytkownicy'},
                        {  value: 'premium', name:'Użytkownicy Premium'},
                        {  value: 'hpa', name:'HPA'},
                     ]}
                />
                <FormInput
                    name="min_players"
                    description="Min ilość graczy"
                    value={selected?.min_players}
                    type="number"
                />
                <FormInput
                    name="max_players"
                    description="Max ilość graczy"
                    value={selected?.max_players}
                    type="number"
                />
                <FormSelect
                    name="second_screen"
                    description="Second screen"
                    options={[{ value: false, name: "NO" }, { value: true, name: "YES" }]}
                />
                <Button variant="primary" type="submit">
                    {selected ? 'Zapisz' : 'Dodaj grę'}
                </Button>
                {selected && (
                    <Button variant="danger" type="reset" onClick={clean}>
                        Wyczyść
                    </Button>
                )}
            </Form>

        </div>
    );
};

const FormSelect = ({
    name, description, options, selected
}) => {
    return (
        <Form.Group>
            <Form.Label>
                {description}
            </Form.Label>
            <Form.Control as="select" name={name}>
                {options.map((item, index) => (
                    <option
                        value={item.id ? item.id : item.value}
                        key={`option_${name}_${index}`}
                        selected={selected && (item.id === selected || item.value === selected)}
                    >
                        {item.name}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
    )
}


const FormInput = ({
    name, description, value, withCheckbox, index, checked, handleRadioChange, type = 'text',
}) => (
    <Form.Group>
        <Form.Label>
            {description}
        </Form.Label>
        <div className="d-flex flex-row">
            {withCheckbox && (
                <Form.Control
                    type="radio"
                    name="correct"
                    className="w-auto mr-2"
                    value={index}
                    checked={checked}
                    onChange={handleRadioChange}
                />
            )}
            <Form.Control type={type} name={name} required defaultValue={value} />
        </div>
    </Form.Group>
);

export default GameForm;
