import React, { Component } from 'react'
import { Card, CardBody} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCogs } from '@fortawesome/free-solid-svg-icons'

class RoomCard extends Component {
    render() {
        return (            
                <Card>
                    <CardBody className="room-card-container">
                        <div className="room-card">
                            <h3 style={{ "overflowWrap": "anywhere" }}>{this.props.title}</h3>
                            <FontAwesomeIcon  onClick={()=>this.props.editRoom(this.props.id)} className="icon room-icon" icon={faCogs} style={{ "cursor": "pointer"}} />
                        </div>
                    </CardBody>
                </Card>

        )
    }
}

export default RoomCard
