import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import { changeColorToOrange, changeColorToBlue, changeColorToGreen, changeColorToRed } from '~root/redux/actions/colorActions';
import { changeThemeToDark, changeThemeToLight } from '~root/redux/actions/themeActions';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from '~root/redux/actions/sidebarActions';
import { SidebarProps } from '~root/shared/prop-types/ReducerProps';
import { accountService } from '~root/_services'

class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
  };

  changeMobileSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeMobileSidebarVisibility());
  };

  changeToDark = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToLight());
  };

  componentDidMount = () => {
    const user=accountService.userValue
    const { dispatch } = this.props;
    if(user)
    switch (user.color) {
      case 'orange':
        dispatch(changeColorToOrange())
        break;
      case 'green':
        dispatch(changeColorToGreen())
        break;
      case 'blue':
        dispatch(changeColorToBlue())
        break;
      case 'red':
        dispatch(changeColorToRed())
        break;
      default:
        dispatch(changeColorToRed())
        break;
    }
    else
    dispatch(changeColorToBlue())

  }

  render() {
    const { sidebar } = this.props;

    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebar.collapse,
    });


    return (
      <>
        <div className={layoutClass}>
          <Topbar
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
          />
        </div>
      </>
    );
  }
}

export default withRouter(connect(state => ({
  sidebar: state.sidebar,
}))(Layout));
