import React, { useState } from 'react';
import {
  Container, Row, Col, Card, Tab, Tabs,
} from 'react-bootstrap';
import Round from './Round';
import Game from './Game';
import Prize from './Prize';
import Play from "./Play";

export default function Creator() {
  const [rerenderGames, setRerenderGames] = useState(false);
  return (
    <Container fluid>
      <Row className="h-100">
        <Col>
          <Card>
            <Card.Body>
              <Tabs>
                <Tab eventKey="round" title="Runda" tabClassName="h6">
                  <Round setRerenderGames={setRerenderGames} />
                </Tab>
                <Tab eventKey="game" title="Gra" tabClassName="h6">
                  <Game rerenderGames={rerenderGames} />
                </Tab>
                {/* <Tab eventKey="play" title="Rozgrywka" tabClassName="h6">
                  <Play />
                  </Tab> */}
                <Tab eventKey="prize" title="Nagrody" tabClassName="h6">
                  <Prize />
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
