import React, {useEffect, useRef, useState} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Button, Badge } from 'react-bootstrap';
import moment from 'moment';
import style from "../style/Table.module.css"
import { alertService, gameService } from '../../../../_services';




export default function Table({ data, handleRowClick, deleteSelected, reloadData }) {

  const [selectedIds, setSelection] = useState([]);
  const [rerender, setRerender] = useState(false);
  const tableRef = useRef();

  useEffect(() => {
    const rerenderInterval = setInterval(() => setRerender(!rerender), 1000)
    return () => {
      clearInterval(rerenderInterval);
    }
  },[rerender])

  const columns = [
    { field: 'name', headerName: 'Nazwa', width: 250 },
    { field: 'table', headerName: 'Rodzaj gry', width: 150, 
      renderCell: (cell) => (
        <div>
          {cell.value.toUpperCase()}
        </div>
      ),
    },
    { field: 'played', headerName: 'Rozegrane gry', width: 250 ,
      renderCell: (cell) =>  (
          <h3 className="mr-1">
            <Badge variant="success">
              Rozegrane: {cell.value}
            </Badge>
          </h3>
        ),
    },
    { field: 'active', headerName: 'Zmiana statusu', width: 250 ,
      renderCell: (cell) =>  (
        <div>
          {
            cell.value ?
              <StatusButton text="dezaktywuj" type={cell.value} data={cell} reload={reloadData} />
            :
              <StatusButton text="aktywuj" type={cell.value} data={cell} reload={reloadData} />
          }
        </div>
        ),
    }
  ];

  return (
    <div style={{ height: '100%', width: '100%', minHeight: 200 }}>
      <DataGrid
        rows={data}
        ref={tableRef}
        columns={columns}
        componentsProps={{reloadData}}
        checkboxSelection
        disableSelectionOnClick
        onRowClick={(props) => {
          handleRowClick(props.row)
        }}
        onSelectionModelChange={(props) => {
          setSelection(props.selectionModel);
        }}
        components={{
          Footer: () => {
            if (selectedIds.length > 0) {
              return (
                <Button
                  variant="danger"
                  type="button"
                  className="ml-3"
                  onClick={() => deleteSelected(selectedIds)}
                >
                  USUŃ ZAZNACZONE
                </Button>
              );
            }
            return null;
          },
        }}
      />
    </div>
  )
}


function StatusButton({text, type, data, reload}) {

  function changeStatus(val) {
    
    gameService.changeStatus(val.id)
    .then((res) => {
      alertService.info("Pomyślnie zmieniono status gry");
      reload();
    })
    .catch(err => {
      alertService.error("Błąd podczas zmiany statusu gry.")
    })
  }

  function setStyles(type) {
    let styles;
      switch(type) {
        case false :
          return styles = style.activeButton;
        case true :
          return styles = style.disableButton;
      }
  }

  return (
    <div 
      className={`${style.statusButton} ${setStyles(type)}`}
      onClick={() => changeStatus(data)}
    >
      {text}
    </div>
  )
}