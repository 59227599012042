import React, {
    useEffect, useState, useImperativeHandle, forwardRef,
  } from 'react';
  import { DataGrid } from '@mui/x-data-grid';
  import { Button, Badge } from 'react-bootstrap';
  import { alertService, roundService } from '~root/_services';
  
  const columns = [
    {
      field: 'name',
      headerName: 'Nazwa',
      editable: false,
      width: 300,
    },
    {
      field: 'question_count',
      headerName: 'Ilość pytań',
      width: 150,
    },
    {
      field: 'scenario',
      headerName: 'Scenariusz',
      width: 150,
    },
    {
      field: 'hashtags',
      headerName: 'Rodzaje pytań',
      width: 'auto',
      renderCell: (cell) => cell.value && cell.value.map(item => (
        <h3 key={cell.value.indexOf(item)}>
          <Badge variant='primary' className="m-1">
             #{item.toUpperCase()}
          </Badge>
          
        </h3>
      ))
    },
  ];
  
  const RoundList = forwardRef(({ selectRound, deleteSelected, setDefaultTags }, ref) => {
    const [rounds, setRounds] = useState([]);
    const [selectedIds, setSelection] = useState([]);
  
    useImperativeHandle(ref, () => ({
      reloadRounds() {
        getRounds();
      },
    }));
  
    const getRounds = () => {
      roundService.getAll()
        .then((res) => {
          setRounds(res);
        })
        .catch((err) => {
          alertService.error(`Nie udało się pobrać pytań. ${err}`);
        });
    };
  
    const handleRowClick = (props) => {
      setDefaultTags([])
      selectRound(props.row);
    };
  
    useEffect(() => {
      getRounds();
    }, []);
  
    return (
      <div style={{ height: '100%', width: '100%', minHeight: 300 }}>
        <DataGrid
          rows={rounds}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          onRowClick={handleRowClick}
          onSelectionModelChange={(props) => {
            setSelection(props.selectionModel);
          }}
          components={{
            Footer: () => {
              if (selectedIds.length > 0) {
                return (
                  <Button
                    variant="danger"
                    type="button"
                    className="ml-3"
                    onClick={() => {
                      deleteSelected(selectedIds);
                      setSelection([]);
                    }}
                  >
                    USUŃ ZAZNACZONE
                  </Button>
                );
              }
              return null;
            },
          }}
        />
      </div>
    );
  });
  
  export default RoundList;
  