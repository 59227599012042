import React, {
  useEffect, useState, useImperativeHandle, forwardRef,
} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Badge } from 'react-bootstrap';
import { alertService, questionService } from '~root/_services';
import Answers from './QuestionStats/components/Answers/Answers';

const columns = [
  // { field: 'id', headerName: 'ID', width: 300 },
  {
    field: 'content',
    headerName: 'Pytanie',
    editable: false,
    width: 300,
  },
  {
    field: 'hashtags',
    headerName: 'Tagi',
    editable: false,
    width: 400,
    renderCell: (cell) => cell.value?.map((item) => (
      <h3 className="mr-1">
        <Badge variant="info">
          #{item}
        </Badge>
      </h3>
    )),
  },
  // {
  //   field: 'answers',
  //   headerName: 'Odpowiedzi',
  //   editable: false,
  //   width: 400,
  //   renderCell: (cell) => 
  //   (
  //     <Answers />
  //   )
  // },
];

const QuestionList = forwardRef(({ selectQuestion, deleteSelected, setDefaultTags  }, ref) => {
  const [questions, setQuestions] = useState([]);
  const [selectedIds, setSelection] = useState([]);
  const [height, setHeight] = useState();

  useImperativeHandle(ref, () => ({
    reloadQuestions() {
      getQuestions();
    },
  }));

  const getQuestions = () => {
    questionService.getAll()
      .then((res) => {
            res.forEach(element => {
              element.hashtags = element.hashtags[0].split(',');
                for( let i=0; i<element.hashtags.length; i++ ) {
                  element.hashtags[i] = element.hashtags[i].trim();
                  element.hashtags[i] == '' && element.hashtags.pop();
                }
            });
            
        setQuestions(res);
      })
      .catch((err) => {
        alertService.error(`Nie udało się pobrać pytań. ${err}`);
      });
  };

  const handleRowClick = (props) => {
    setDefaultTags([]);
    selectQuestion(props.row);
  };

  useEffect(() => {
    getQuestions();
  }, []);

   

  return (
    <div style={{ height: '100%', width: '100%', minHeight: 300 }}>
      <DataGrid
        rowHeight={height}
        rows={questions}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        onRowClick={handleRowClick}
        onSelectionModelChange={(props) => {
          setSelection(props.selectionModel);
        }}
        components={{
          Footer: () => {
            if (selectedIds.length > 0) {
              return (
                <Button
                  variant="danger"
                  type="button"
                  className="ml-3"
                  onClick={() => {
                    deleteSelected(selectedIds);
                    setSelection([]);
                  }}
                >
                  USUŃ ZAZNACZONE
                </Button>
              );
            }
            return null;
          },
        }}
      />
    </div>
  );
});

export default QuestionList;
