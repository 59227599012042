/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';

const PrizeForm = ({
  selected, clean, savePrize, withPoints, type
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const values = {
      name: e.currentTarget.elements.name.value,
      description: e.currentTarget.elements.description.value,
      stock: e.currentTarget.elements.stock.value,
      language: e.currentTarget.elements.language.value,
      icon : e.currentTarget.elements.icon.files[0],
      type : e.currentTarget.elements.type.value,
    };

    console.log(values)

    if (withPoints) {
      Object.assign(values,
        {
          points: e.currentTarget.elements.points.value,
        }
      )
    }

    savePrize(selected?.id, values);

    e.currentTarget.reset();
  };

  useEffect(() => {

  }, []);

  return (
    <div>
      {selected?.id && <h3>EDYCJA {selected.id}</h3>}
      <Form onSubmit={handleSubmit}>
        <input value={type} type="hidden" name="type" />
        <FormInput
          name="name"
          description="Nazwa gry"
          value={selected?.name}
        />
        {withPoints &&
          <FormInput
            name="points"
            description="Punkty"
            value={selected?.points}
            type="number"
          />
        }
        <div className="mb-3">
          <label>Wybierz ikonę nagrody</label><br />
          <input type="file" accept="image/png, image/jpeg" name="icon"/>
        </div>
        <FormInput
          name="description"
          description="Opis nagrody"
          value={selected?.description}
        />
        <FormInput
        type="number"
          name="stock"
          description="Dostępna ilość"
          value={selected?.stock}
        />
        <FormInput
          name="language"
          description="Język"
          value={selected?.language}
        />
        <Button variant="primary" type="submit">
          {selected ? 'Zapisz' : 'Dodaj nagrodę'}
        </Button>
        {selected && (
          <Button variant="danger" type="reset" onClick={clean}>
            Wyczyść
          </Button>
        )}
      </Form>

    </div>
  );
};

const FormInput = ({
  name, description, value, withCheckbox, index, checked, handleRadioChange, type = 'text',
}) => (
  <Form.Group>
    <Form.Label>
      {description}
    </Form.Label>
    <div className="d-flex flex-row">
      {withCheckbox && (
        <Form.Control
          type="radio"
          name="correct"
          className="w-auto mr-2"
          value={index}
          checked={checked}
          onChange={handleRadioChange}
        />
      )}
      <Form.Control type={type} name={name} required defaultValue={value} />
    </div>
  </Form.Group>
);

export default PrizeForm;
