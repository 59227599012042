/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import Tags from '../../../components/Tags/Tags';

const RoundForm = ({
  selected, clean, saveRound, formRef, defaultTags, setDefaultTags
}) => {

  const handleSubmit = (e) => {
    e.preventDefault();

    const HashElement = e.currentTarget.elements.hashtags.firstChild.children;
    const HashArray = [];
      for (let i=0; i<HashElement.length-1; i++){
        HashArray.push( HashElement[i].firstChild.innerText )
      }

      for (let i=0; i<HashArray.length; i++) {
        HashArray[i] = HashArray[i].toUpperCase();
     }

    const values = {
      name: e.currentTarget.elements.name.value,
      scenario: e.currentTarget.elements.scenario.value,
      points: e.currentTarget.elements.points.value,
      time: e.currentTarget.elements.time.value,
      hashtags: HashArray,
      question_count: e.currentTarget.elements.question_count.value,
      key: e.currentTarget.elements.key.value,
      studio: e.currentTarget.elements.studio.value,
      finish: e.currentTarget.elements.finish.value,
      
    };
    console.log(values)

    saveRound(selected?.id, values);

    setDefaultTags([]);
    e.currentTarget.reset();
  };

  useEffect(() => {

  }, []);

  return (
    <div>
      {selected?.id && <h3>EDYCJA {selected.id}</h3>}
      <Form onSubmit={handleSubmit} ref={formRef}>
        <FormInput
          name="name"
          description="Nazwa rundy"
          value={selected?.name}
        />
        <FormSelect
          name="scenario"
          description="Scenariusz rundy"
          options={['time','firstwin', 'wrongout']}
        />
        <FormInput
          name="points"
          description="Punkty za rundę"
          value={selected?.points}
          type="number"
        />
        <FormInput
          name="time"
          description="Czas trwania pytania"
          value={selected?.time}
          type="number"
        />

        <label> Rodzaje pytań </label>
        <fieldset id='hashtags' name='hashtags'>
           <Tags 
            name="hashtags" 
            description="Hashtagi pytań" 
            value={ selected?.hashtags ? selected.hashtags : []} 
            defaultTags={defaultTags} 
            setDefaultTags={setDefaultTags}
           />
        </fieldset>

        <FormInput name="question_count" description="Ilość pytań" value={selected?.question_count} />
        <FormSelect
          name="key"
          description="Klucz pytań - NOT IMPLEMENTED YET"
          options={['default']}
          disabled
        />
        <FormSelect
          name="studio"
          description="Wygląd studia - NOT IMPLEMENTED YET"
          options={['default']}
          disabled
        />
        <FormSelect
          name="finish"
          description="Zakończenie rundy - NOT IMPLEMENTED YET"
          options={['default']}
          disabled
        />
        <Button variant="primary" type="submit">
          {selected ? 'Zapisz' : 'Dodaj rundę'}
        </Button>
        {selected && (
          <Button variant="danger" type="reset" onClick={clean}>
            Wyczyść
          </Button>
        )}
      </Form>

    </div>
  );
};

const FormInput = ({
  name, description, value, withCheckbox, index, checked, handleRadioChange, type = 'text',
}) => (
  <Form.Group>
    <Form.Label>
      {description}
    </Form.Label>
    <div className="d-flex flex-row">
      {withCheckbox && (
        <Form.Control
          type="radio"
          name="correct"
          className="w-auto mr-2"
          value={index}
          checked={checked}
          onChange={handleRadioChange}
        />
      )}
      <Form.Control type={type} name={name} required defaultValue={value} />
    </div>
  </Form.Group>
);

const FormSelect = ({
  name, description, options, disabled
}) => (
  <Form.Group>
    <Form.Label>
      {description}
    </Form.Label>
    <Form.Control as="select" name={name} disabled={disabled}>
      {options.map((item, index) => (
        <option value={item} key={`option_${name}_${index}`}>{item}</option>
      ))}
    </Form.Control>
  </Form.Group>
);

export default RoundForm;
